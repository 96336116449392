//  libraries
import React from 'react'

//  styles
import { ItemNew, CarouselStyles } from './slider.style'
import urlSlug from 'url-slug'
import { Link, navigate } from 'gatsby'

const Index = ({ images }) => {
  const OnclickPost = (url) => {
    navigate(`/news/${url}`)
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 4,
      paritialVisibilityGutter: 10
    },
    tablet: {
      breakpoint: { max: 1439, min: 768 },
      items: 3,
      paritialVisibilityGutter: 10
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30
    }
  }

  const CustomDot = ({ index, active, onClick, carouselState }) => {
    const { currentSlide, totalItems, slidesToShow } = carouselState

    let last = totalItems - slidesToShow
    let lower = currentSlide - 1
    let upper = currentSlide + 1
    upper = lower < 0 ? upper - lower : upper
    lower = upper > last ? lower - (upper - last) : lower

    if ((lower <= index && index <= upper) || index === 0 || index === last)
      return (
        <span onClick={onClick} className={`dot ${active ? 'active' : ''}`}>
          {index + 1}
        </span>
      )
    else if (index === 1 || index === last - 1)
      return <span className="dot space">...</span>
    return null
  }

  function transformText(text) {
    // Remove HTML tags
    const plainText = text.replace(/<[^>]+>/g, '')

    // Remove newlines and extra spaces
    const trimmedText = plainText.replace(/\n/g, '').replace(/\s+/g, ' ').trim()

    return trimmedText
  }

  return (
    <CarouselStyles
      arrows={false}
      itemClass="itemNew"
      responsive={responsive}
      showDots
      draggable
      containerClass="containerNews"
      slidesToSlide={1}
      customDot={<CustomDot />}
    >
      {images.map((d, index) => {
        return (
          <ItemNew key={index} onClick={() => OnclickPost(urlSlug(d.title))}>
            <img
              style={{ width: '100%' }}
              src={d.image.url || ''}
              alt={d.image.alt_text || 'empty image'}
              className="imgPost"
            />
            <div className="contentPost">
              <h2>{d.title}</h2>
              <p>{transformText(d.summary)}</p>
              <Link to={`/news/${urlSlug(d.title)}`}>Read more</Link>
            </div>
          </ItemNew>
        )
      })}
    </CarouselStyles>
  )
}

export default Index
