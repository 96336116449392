//  libraries
import React from 'react'

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

//  containers
import Section1Home from 'containers/home/Section1Home'
import Section2HomeNew from 'containers/home/Section2HomeNew'
// import Section3Home from 'containers/home/Section3Home'
import CarrierResources from 'containers/home/CarrierResources'
import TeamWorking from 'containers/home/TeamWorkingSection'
import WatchNews from 'containers/home/WatchOurNews'

const Index = () => {
  return (
    <Layout>
      <SEO
        title="Freight Broker with Instant Capacity and Trucking App | Edge Logistics"
        description="Digital freight broker with instant capacity for shippers. Free demo of CAPACITY, our freight management and trucking app. Carriers, join us. Get the Edge!"
        keywords={[
          'Freight broker',
          'digital freight broker',
          'trucking app,freight management'
        ]}
      />
      <Section1Home />
      <Section2HomeNew />
      {/* <Section3Home /> */} {/* CAPACITY */}
      <CarrierResources />
      <TeamWorking />
      <WatchNews />
    </Layout>
  )
}

export default Index
