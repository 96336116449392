import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { Carousel } from 'react-responsive-carousel'
import { moveInLeft } from 'styles/animation'

export const BackgroundContainer = styled(BackgroundImage)`
  padding: 6rem 0;
  display: flex;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }

  .shadow {
    width: 100%;
    display: none;
    position: absolute;
    bottom: 0;
    height: 25rem;
    z-index: 5;
    background: transparent
      linear-gradient(1deg, #2575a5 0%, #2e7aa9f5 5%, #ffffff00 70%) 0% 0%
      no-repeat padding-box;
    opacity: 0.55;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 120rem;
  margin: 0 auto;
  padding: 6rem 5rem;
  gap: 4em;
  align-items: center;

  .request-button {
    width: 200px;
    font-family: 'Poppins';
    background: #147efb;
    border: 4px solid #147efb;
    font-weight: 500;
    font-size: 1.6rem;
    color: #ffffff;

    &:hover {
      background: #147efb;
      color: #ffffff;
      border: 4px solid #147efb;
    }
  }

  @media (min-width: 1440px) and (max-width: 1749px) {
    max-width: 130rem;
  }

  @media (min-width: 1750px) {
    max-width: 135rem;
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 2rem;
  }
`

export const Content = styled.div`
    /* ${moveInLeft('2s', 'ease-out')} */
  display: flex;
  flex-direction: column;
  justify-content: center;


  #title {
    font-weight: 800;
    letter-spacing: 0;
    color: #373F49;
    font-size: 5rem;
    line-height: 8rem;
    margin-bottom: 2.5rem;
    margin-top: 2rem;
  }

  #subtitle {
    font-weight: 700;
    font-size: 3.5rem;
    color: #147efb;
    margin-bottom: 2.5rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: 0;
    color: #788293;
    line-height: 4.2rem;
    margin-bottom: 4.5rem;
  }

  @media only screen and (max-width: 500px) {

    #title {
      font-size: 3.7rem;
      line-height: 4.5rem;
    }

    #subtitle {
      font-size: 2.5rem;

    }

    h3 {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {

    #title {
      font-size: 4em;
    }

    #subtitle {
      font-size: 3em;
    }

    h3{
      font-size: 1.8rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1441px) {

    #title {
      font-size: 3.5rem;
      line-height: 5rem;
    }

    #subtitle {
      font-size: 2.5em;
    }

    h3 {
      line-height: 3.5rem;
      font-size: 2rem;
    }
  }

  @media (max-width: 900px) {
    text-align: center;
  }
`

export const SlideContainer = styled(Carousel)`
  outline: none;
  position: absolute;
  top: 3%;
  width: 90%;
  height: 94%;
  left: 5%;
  z-index: -1;

  .carousel {
    width: 100%;
    height: 100%;
  }

  .carousel .slide img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  @media only screen and (max-width: 320px) {
    display: none;
  }
`

export const GifDiv = styled.div`
    /* ${moveInLeft('2s', 'ease-out')} */
  position: relative;
  margin: auto;
  width: 90%;

  @media (min-width: 500px) {
    width: 50%;
  }

  img.phone {
    display: block;
    width: 100%;
  }

  @media only screen and (max-width: 360px) {
    img.phone {
      display: none;
    }
  }
`

export const ComputerImage = styled.img`
  width: 334px;
  height: 209px;
  order: 1;
  margin-bottom: 42px;

  @media screen and (min-width: 768px) {
    order: 2;
    margin-bottom: 0;
    margin-top: 30px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 421.3px;
    height: 258px;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    width: 421.3px;
    height: 258px;
    margin-top: 30px;
  }

  @media (min-width: 1440px) {
    width: 516.1px;
    height: 323px;
    margin-top: 100px;
  }

  @media (min-width: 1920px) {
    margin: 100px auto 6px auto;
  }

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`
