import styled from 'styled-components';

export const CarrierSection = styled.section`
  background: #147efb;
  background: radial-gradient(
    circle farthest-side at 50% 80%,
    #147efb -15%,
    rgba(16, 20, 24, 1) 70%
  );
  
  display: grid;
  grid-auto-flow: row;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
  

  @media (min-width: 1024px) {
    background: #147efb;
    background: radial-gradient(
      circle farthest-side at 50% 90%,
      #147efb -15%,
      rgba(16, 20, 24, 1) 70%
    );
  }
  @media (min-width: 1024px) {
    background: #147efb;
    background: radial-gradient(
      circle farthest-side at 50% 90%,
      #147efb -15%,
      rgba(16, 20, 24, 1) 80%
    );
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  color: white;
  margin: auto;
  max-width: 75%;

  @media (min-width: 1024px) {
    max-width: 60%;
  }
  @media (min-width: 1440px) {
    max-width: 40%;
  }

  h1 {
    font-size: 3.7rem;
    line-height: 4rem;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: 0;

    @media only screen and (min-width: 767px) {
      font-size: 5rem;
    }
    @media only screen and (min-width: 1440px) {
      font-size: 4.5rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 2em;
    letter-spacing: 0;
    margin-top: 12%;

    @media only screen and (min-width: 767px) {
      font-size: 2.5rem;
      line-height: 3.6rem;
      margin-top: 4%;
    }
    @media only screen and (min-width: 1440px) {
      font-size: 2.2rem;
    }
  }
`;

export const SectionContainer = styled.div`
  position: relative;

  @media only screen and (min-width: 767px) {
    background-image: url(${(props) => props.backgroundUrl});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .request-button {
    width: 80%;
    background: transparent;
    border: 4px solid white;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0;
    color: #ffffff;
    margin-top: 5%;

    &:hover {
      background: white;
      color: #147efb;
    }

    @media only screen and (min-width: 767px) {
      width: 200px;      
      margin-top: 10%;
    }
    @media only screen and (min-width: 900px) {
      margin-top: 6%;
    }
    @media only screen and (min-width: 1800px) {
      margin-top: 4%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ImageSection = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
  }
  @media only screen and (min-width: 900px) {
    width: 70%;
  }
  @media only screen and (min-width: 1200px) {
    width: 55%;
  }
  @media only screen and (min-width: 1350px) {
    width: 50%;
  }
  @media only screen and (min-width: 1440px) {
    width: 70%;
    flex-wrap: nowrap;
    padding-top: 4%;
  }
    @media only screen and (min-width: 1700px) {
    width: 50%;
    justify-content: space-between;
  }
`;

export const Staffing = styled.img`
  width: 60%;

  @media only screen and (min-width: 767px) {
    width: 50%;
  }
  @media only screen and (min-width: 1440px) {
    width: 35%;
  }
`;

export const TriumphPay = styled.img`
  width: 65%;
  padding: 5%;

  @media only screen and (min-width: 767px) {
    width: 40%;
    padding: 0;
  }
  @media only screen and (min-width: 1024px) {
    width: 45%;
  }
  @media only screen and (min-width: 1440px) {
    width: 30%;
  }
`;

export const KeepTruckin = styled.img`
  width: 50%;
  padding-top: 2%;

  @media only screen and (min-width: 767px) {
    width: 35%;
    padding-top: 0%;
  }
  @media only screen and (min-width: 1024px) {
    width: 45%;
  }
  @media only screen and (min-width: 1440px) {
    width: 30%;
  }
`;
