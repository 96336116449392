//libraries
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

//components
import SliderNews from 'components/home/slider'

//styles
import { ContainerNews, CarouselWrapper } from './watchNew.style'

const WatchNews = () => {
  const Data = useStaticQuery(graphql`
    {
      strapiHome {
        dataHome {
          sliderSection {
            title
          }
        }
      }
      allPost {
        nodes {
          id
          title
          image {
            url
            alt_text
          }
          summary
          updated
          slug
        }
      }
    }
  `)

  const { dataHome } = Data.strapiHome

  const posts = Data.allPost.nodes

  return (
    <ContainerNews>
      <h1>{dataHome.sliderSection.title}</h1>
      <CarouselWrapper>
        <SliderNews images={posts} />
      </CarouselWrapper>
    </ContainerNews>
  )
}

export default WatchNews
