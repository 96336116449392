//  libraries
import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import ReactGA from 'react-ga'
import Fade from 'react-reveal/Fade'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

//  components
import CustomButton from 'components/CustomButton'

//  styles
import {
  Container,
  Content,
  BackgroundContainer,
  ComputerImage
} from './section1Home.style.js'

const Section1Home = () => {
  const { strapiHome } = useStaticQuery(graphql`
    {
      strapiHome {
        dataHome {
          info: edgeLogisticCapacity {
            content
            subTitle
            title
            imageAltText
          }
        }
        mainSection {
          publicURL
        }
        OldBackgroundMainSection {
          backgroundImage: childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        phoneSlide {
          url
        }
        CustomerScreenshot {
          childImageSharp {
            fixed(width: 615, height: 386) {
              src
            }
          }
        }
      }
    }
  `)

  const {
    dataHome: { info },
    OldBackgroundMainSection: { backgroundImage },
    CustomerScreenshot
  } = strapiHome

  // const handleCarrierSignUp = (event) => {
  //   ReactGA.event({
  //     category: 'Outbound Link',
  //     action: 'click',
  //     label: event.target.href
  //   })
  //   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //     window.open(process.env.GATSBY_CAPACITY_SIGN_UP_URL)
  //   } else {
  //     window.gtag('event', 'conversion', {
  //       send_to: 'AW-342968788/c4LBCILn1o4DENSTxaMB',
  //       event_callback: () => {
  //         window.open(process.env.GATSBY_CAPACITY_SIGN_UP_URL)
  //       }
  //     })
  //   }
  // }

  const navigateToCapacity = (event) => {
    navigate('capacity')
    ReactGA.event({
      category: 'Outbound Link',
      action: 'click',
      label: event.target.href
    })
  }

  return (
    <BackgroundContainer Tag={'div'} fluid={backgroundImage.fluid}>
      <Container>
        <Content>
          <Fade left>
            <h2 id="title">{info.title}</h2>
            <h2 id="subtitle">{info.subTitle}</h2>
            <h3>{info.content}</h3>
            <CustomButton
              className="request-button"
              onClick={navigateToCapacity}
              text="Ship with Edge"
            />
            {/* <CustomButton
              className="request-button"
              onClick={handleCarrierSignUp}
              text="Carrier Sign Up"
            /> */}{' '}
            {/* CAPACITY */}
          </Fade>
        </Content>
        <ComputerImage src={CustomerScreenshot.childImageSharp.fixed.src} />
        {/* <Fade right>
          <GifDiv>
            <SlideContainer
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              useKeyboardArrows={false}
              stopOnHover={false}
              swipeable={false}
              dynamicHeight={false}
              infiniteLoop={true}
            >
              {phoneSlide.map((d, index) => (
                <div key={index}>
                  <img src={d.url} alt={`imgSlide${index}`} />
                </div>
              ))}
            </SlideContainer>
            <img
              className="phone"
              src={mainSection.publicURL}
              alt={info.imageAltText}
            />
          </GifDiv>
        </Fade> */}{' '}
        {/* CAPACITY  */}
      </Container>
      <div className="shadow"></div>
    </BackgroundContainer>
  )
}

export default Section1Home
