import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

//  components
import CustomButton from 'components/CustomButton'

//  styles
import {
  CarrierSection,
  ImageSection,
  TextContainer,
  SectionContainer,
  KeepTruckin,
  TriumphPay,
  Staffing,
  ButtonContainer
} from './carrierResources.style'

const CarrierResources = () => {
  const data = useStaticQuery(graphql`
    {
      strapiHome {
        dataHome {
          carrierResources {
            title
            description
            buttom
          }
        }
        OldPartnersBackground {
          publicURL
        }
        partnersKeepTruckin {
          publicURL
        }
        leanstaffingLogo {
          publicURL
        }
        partnersTriumphPay {
          publicURL
        }
      }
    }
  `)

  const {
    dataHome,
    partnersTriumphPay,
    partnersKeepTruckin,
    leanstaffingLogo,
    OldPartnersBackground
  } = data.strapiHome
  const { carrierResources } = dataHome

  return (
    <CarrierSection>
      <TextContainer>
        <h1>{carrierResources.title}</h1>
        <p>{carrierResources.description}</p>
      </TextContainer>
      <SectionContainer backgroundUrl={OldPartnersBackground.publicURL}>
        <ImageSection>
          <Staffing src={leanstaffingLogo.publicURL} alt="Leanstaffing" />
          <TriumphPay src={partnersTriumphPay.publicURL} alt="TriumphPay" />
          <KeepTruckin src={partnersKeepTruckin.publicURL} alt="vendors" />
        </ImageSection>
        <ButtonContainer>
          <CustomButton
            className="request-button"
            text={carrierResources.buttom}
            onClick={() => navigate('/partner-resources')}
          />
        </ButtonContainer>
      </SectionContainer>
    </CarrierSection>
  )
}

export default CarrierResources
