import styled from 'styled-components'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';

export const CarouselStyles = styled(Carousel)`
  .itemNew {
    height: 324px;
  }

  @media screen and (min-width: 1024px) {
    .itemNew {
      height: 410px;
    }
  }

  .react-multi-carousel-track {
    height: 388px;
    padding-left: 3px;

    @media screen and (min-width: 1024px) {
      height: 474px;
    }
  }

  .react-multi-carousel-dot-list {
    display: grid;
    grid-auto-flow: column;
  }

  .dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #147efb;
    color: #147efb;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: 32px;
    cursor: pointer;

    &.space {
      border: 0;
      cursor: initial;
    }

    &.active {
      background: #147efb;
      color: white;
    }

    @media screen and (min-width: 768px) {
      margin-right: 40px;
    }

    @media screen and (min-width: 1024px) {
      margin-right: 54px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

const ItemNew = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 100%;
  width: 332px;

  @media screen and (min-width: 768px) {
    width: 200px;
  }

  @media screen and (min-width: 1024px) {
    width: 240px;
  }

  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .imgPost {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 110px !important;
    object-fit: cover;

    @media screen and (min-width: 1024px) {
      height: 160px !important;
    }
  }

  .contentPost {
    position: relative;
    padding: 16px;

    h2 {
      display: -webkit-box;
      overflow: hidden;
      font-weight: 500;
      font-size: 1.6rem;
      color: #147efb;
      line-height: 20px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
    }

    p {
      display: -webkit-box;
      font-size: 1.2rem;
      overflow: hidden;
      line-height: 16px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin: 16px 0;
    }

    @media screen and (min-width: 768px) {
      h2 {
        -webkit-line-clamp: 3;
      }
    }

    @media screen and (min-width: 1024px) {
      padding: 24px;
      p {
        margin: 24px 0;
      }
    }
  }

  .datePost {
    position: absolute;
    right: 0;
    top: -42px;
    background: #147efb;
    border-radius: 18px 0px 0px 0px;
    opacity: 1;
    padding: 1rem;

    span {
      color: #ffffff;
      padding: 0rem 2rem;
      font-weight: 600;
      font-size: 1.5rem;
    }
  }

  & h2 {
    color: #373f49;
    font-size: 1.6rem;
    margin: 2rem 0;
  }

  & p {
    color: #788293;
    font-size: 1.6rem;
    font-weight: 500;
  }

  & a {
    color: #147efb;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.3rem;
  }
`

export { ItemNew }
