import styled from 'styled-components'

import Substract from 'assets/image/app/substract.png'
import SubstractHover from 'assets/image/app/substract-hover.png'

export const TeamWorkingSection = styled.div`
  background: url(${(props) => props.url}) center center / cover fixed no-repeat;
  display: grid;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }

  *,
  *::after. *::before {
    box-sizing: border-box;
  }

  .content {
    display: grid;
    max-width: 130rem;
    margin: 0 auto;
    padding: 14rem 5rem;
    width: 100%;
    grid-gap: 2rem;
    position: relative;

    @media (min-width: 1100px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const TextContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  text-align: center;
  grid-gap: 2em;
  font-family: 'Poppins';
  max-width: 550px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    justify-items: start;
    text-align: left;
    margin: 0;
  }

  .title {
    color: #373f49;
    font-size: 3.2rem;
    line-height: 1.3em;
    margin: 0;

    @media (min-width: 500px) {
      font-size: 5rem;
    }

    @media (min-width: 768px) {
      font-size: 4.5rem;
    }
  }

  .description {
    background-color: transparent;
    font-weight: 500;
    font-size: 20px;
    max-width: 480px;
    color: #788293;
    font-weight: 300;
    letter-spacing: -0.01em;
    line-height: 1.3em;
    margin: 0;

    @media (min-width: 500px) {
      font-size: 24px;
    }
    @media (min-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 991px) {
      font-size: 25px;
    }
  }

  .button {
    border: 4px solid #147efb;
    background: #147efb;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 1.8rem;
    color: #ffffff;
    font-size: 1.6rem;
    margin-top: 13rem;

    &:hover {
      background: #147efb;
      border: 4px solid #147efb;
      color: white;
    }
  }
`

export const GalleryContainer = styled.div`
  max-width: 100%;
  overflow: visible;

  .image-gallery {
    width: 100%;
    height: 100%;
  }

  .image-gallery-content {
    position: unset;
    width: 100%;
    height: 100%;

    .image-gallery-slide-wrapper {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      position: absolute;
      top: 42rem;
      left: 0;
      padding: 0 5rem;

      @media (min-width: 361px) {
        top: 39rem;
      }

      @media (min-width: 370px) {
        top: 34rem;
      }

      @media (min-width: 500px) {
        top: 46rem;
      }

      @media (min-width: 508px) {
        top: 40rem;
      }

      @media (min-width: 768px) {
        top: 34rem;
      }

      @media (min-width: 991px) {
        top: 38rem;
      }

      @media (min-width: 1100px) {
        width: calc(50% - 1rem);
      }

      > span {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1em;
        justify-content: center;
        height: 30px;

        @media (min-width: 768px) {
          justify-content: start;
        }

        .image-gallery-left-nav,
        .image-gallery-right-nav {
          position: relative;
          transform: none;
          padding: 0;
          font-size: 0;
          margin-top: -15px;
          transition: all 0.25s ease-in-out;
          width: 30px;
          height: 100%;
          background-repeat-x: repeat;
          background-position: center;
          background-size: contain;

          &:hover {
            &::before {
              background-color: #147efb;
            }
            &::after {
              background-color: #147efb;
            }
          }

          &:focus {
            outline: unset;
          }

          &::before,
          &::after {
            content: '';
            width: 10px;
            height: 2px;
            display: block;
            position: absolute;
            transition: inherit;
          }

          &::before {
            top: 11px;
          }

          &::after {
            bottom: 11px;
          }
        }

        .image-gallery-left-nav {
          width: 25px;
          background-image: url(${Substract});

          &:hover {
            width: 30px;
            background-image: url(${SubstractHover});
          }

          &::before {
            background-color: #343d48;
            transform: rotate(-36deg);
            left: 0;
          }

          &::after {
            background-color: #343d48;
            transform: rotate(36deg);
            left: 0;
          }
        }

        .image-gallery-right-nav {
          background-image: url(${SubstractHover});

          &::before {
            background-color: #147efb;
            transform: rotate(36deg);
            left: 20px;
          }
          &::after {
            background-color: #147efb;
            transform: rotate(-36deg);
            left: 20px;
          }
        }
      }

      .image-gallery-swipe {
        .image-gallery-slide {
          background: transparent;

          .image-gallery-description {
            background: transparent;
            bottom: 0;
            color: #000;
            position: relative;

            .testimonialDetails {
              display: grid;
              grid-gap: 1em;

              @media (max-width: 768px) {
                text-align: center;
              }

              .testimonialName {
                line-height: 33px;
                color: #147efb;
                font-family: 'Poppins';
                font-weight: 800;
                margin-bottom: -3px;
                text-transform: capitalize;
                font-size: 3.2rem;

                @media (min-width: 500px) {
                  font-size: 3.7rem;
                }
              }

              .testimonialDesignation {
                font-size: 16px;
                line-height: 1.3em;
                color: #788293;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .image-gallery-thumbnails-wrapper {
      width: 100%;
      height: 100%;

      .image-gallery-thumbnails {
        padding: 0;
        width: 100%;
        height: 100%;

        @media (min-width: 1100px) {
          overflow: visible;
        }
      }

      .image-gallery-thumbnails-container {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          aspect-ratio: 1/1;
          border-radius: 50%;
          object-fit: cover;
          display: block;
        }

        .image-gallery-thumbnail {
          width: 100%;
          transition: all 0.35s ease;
          border: 0;

          .image-gallery-thumbnail-inner {
            max-width: 300px;
            width: 100%;
            transition: all 0.35s ease;
            margin: auto;
          }

          @media (min-width: 500px) {
            width: calc(50% - 15px);
            aspect-ratio: 1/1;
            margin-right: 50px;

            .image-gallery-thumbnail-inner {
              width: 80%;
              margin: 10% 10%;
              aspect-ratio: 1/1;
            }

            &.active {
              .image-gallery-thumbnail-inner {
                width: 100%;
                margin: 0;
              }
            }
          }

          @media (min-width: 680px) {
            width: calc(33.33% - 35px);
            margin-right: 60px;
          }

          @media (min-width: 800px) {
            width: calc(25% - 43px);
            margin-right: 60px;
          }

          @media (min-width: 1100px) {
            position: absolute;
            width: ${(props) => props.size}px;
            margin-right: 0;
            top: calc(50% - ${(props) => props.size}px / 2);
            left: calc(50% - ${(props) => props.size}px / 2);
          }
        }

        .image-gallery-thumbnail.active {
          z-index: 99999;
        }

        .image-gallery-thumbnail:nth-child(1) {
          @media (min-width: 1100px) {
            transform: translate(-${(props) => props.size}px, 0);
          }
        }
        .image-gallery-thumbnail:nth-child(2) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => 360 / props.items}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => 360 / props.items}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(3) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 2}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 2}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(4) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 3}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 3}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(5) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 4}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 4}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(6) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 5}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 5}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(7) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 6}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 6}deg);
          }
        }
        .image-gallery-thumbnail:nth-child(8) {
          @media (min-width: 1100px) {
            transform: rotate(${(props) => (360 / props.items) * 7}deg)
              translate(-${(props) => props.size}px, 0)
              rotate(-${(props) => (360 / props.items) * 7}deg);
          }
        }
      }
    }
  }
`
