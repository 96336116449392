import React from 'react';

const SliderDes = ({ title, data }) => {
  
  return (
    <div className="testimonialDetails">
      <p className="testimonialName">{data.name}</p>
      <span className="testimonialDesignation">{data.position}</span>
    </div>
  );
};

export default SliderDes;
