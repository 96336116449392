import styled from 'styled-components'

export const ContainerNews = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  height: 560px;

  h1 {
    font-size: 2.8rem;
    line-height: 32px;
    color: #383f48;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }

  @media screen and (min-width: 768px) {
    height: 620px;
    padding: 80px 68px;

    h1 {
      font-size: 4.4rem;
      line-height: 66px;
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 749px;
    padding: 80px 120px;

    h1 {
      margin-bottom: 49px;
    }
  }

  @media screen and (min-width: 1440px) {
    padding: 140px 198px;
    height: 874px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1920px;
    margin: 0 auto;
    padding: 141px 396px;
    height: 895px;
  }
`

export const CarouselWrapper = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  row-gap: 16px;
`
