import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  padding-top: 15rem;
  padding-bottom: 10rem;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }

  h2 {
    font-weight: 800;
    letter-spacing: 0;
    color: #000;
    font-size: 5rem;
    line-height: 8rem;
    margin-bottom: 6rem;
  }

  .learn-button {
    margin-top: 6.5rem;

    border: 4px solid #ffffff;
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
    background: #147efb;
  }

  @media only screen and (max-width: 500px) {
    position: relative;
    z-index: 5;
    padding-top: 10rem;
    padding-bottom: 13rem;

    h2 {
      font-size: 3.2rem;
      line-height: 1rem;
      margin-bottom: 4rem;
    }

    .learn-button {
      margin-top: 50px;
      width: 70%;
      /* max-width: 260px; */
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    padding-top: 10rem;
    padding-bottom: 13rem;
    h2 {
      line-height: 1rem;
      margin-bottom: 3rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1440px) {
    padding-top: 5rem;
    padding-bottom: 10rem;
    h2 {
      font-size: 4rem;
    }
  }
`

export const Content = styled.div`
  padding: 0 5rem;
  max-width: 130rem;

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }

  .cardsMobile {
    display: none;
    margin-top: 20px;
    h3 {
      text-align: center;
    }
  }

  .slider-next-icon {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .slider-prev-icon {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  @media only screen and (max-width: 500px) {
    padding: 0;
    max-width: unset;
    .carousel {
      max-width: unset;
    }
    .cards {
      grid-template-columns: repeat(1, 1fr);
      display: none;
    }
    .cardsMobile {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    .cards {
      grid-template-columns: repeat(1, 1fr);
      display: none;
    }
    .cardsMobile {
      display: block;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 990px) {
    padding: 0 2rem;
    .cards {
      grid-template-columns: repeat(2, 3fr);
    }
  }

  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    padding: 0 2rem;
    gap: 1rem;
    .cards {
      grid-template-columns: repeat(3, 250px);
    }
    
  }

  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    padding: 0 2rem;
    gap: 1rem;
    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
    
  }

  @media (min-width: 1750px) {
    padding: 0 3rem;
    .cards {
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }
  }
`

export const Card = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
  display: grid;
  position: relative;
  :hover {
    z-index: 900;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.7rem;
    letter-spacing: 0;
    color: #373f49;
    text-align: center;
    flex: 1.5;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 1.6rem;
    line-height: 3.4rem;
    letter-spacing: 0;
    color: #788293;
    text-align: center;
    flex: 3;
  }

  @media only screen and (max-width: 500px) {
    width: 32rem;
    width: 100%;
    padding: 0rem 2rem;
    justify-content: center;

    h3 {
      margin-bottom: 0;
    }

    p {
      line-height: 2rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    max-width: 30rem;
    padding: 2rem;
    justify-self: center;
    z-index: 700;

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 991px) {
    max-width: 30rem;

   
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1300px) {
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
   
  
  }
`

export const InnerCard = styled.div`
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  z-index: 700;
  grid-area: 1 / 1 / 1 / 1;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.7rem;
    letter-spacing: 0;
    color: #000;
    text-align: center;
    flex: 1.5;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 1.6rem;
    line-height: 3.4rem;
    letter-spacing: 0;
    color: #788293;
    text-align: left;
    flex: 3;
    padding: 3rem;
  }

  @media only screen and (max-width: 500px) {
    width: 32rem;
    width: 100%;
    padding: 0rem 2rem;
    justify-content: center;

    h3 {
      margin-bottom: 0;
    }

    p {
      line-height: 2rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    max-width: 30rem;
    padding: 2rem;
    justify-self: center;

    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 991px) {
    padding: 3.5rem;
    max-width: 30rem;
    height: 50%;
   
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1440px) {
    height: 50%;
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }
`
const CardAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0.15);
  }
  60% {
    transform: scale(0.6);
  }
  80% {
    transform: scale(0.80);
  }
  100% {
    transform: scale(1);
  }
`

export const UpperCard = styled.div`
  width: 180%;
  max-width: 300px;
  background-color: #ffffff;
  box-shadow: 7px 7px 25px rgb(7 18 25 / 0.45);
  z-index: 5000;
  grid-area: 1 / 1 / 2 / 2;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem;
  display: flex;
  position: absolute;
  animation: ${CardAnimation};
  animation-duration: 0.2s;
  overflow: hidden;
  margin-left: -50px;
  border-radius: 10px;
  text-align: center;
  min-height: 520px;
  h3 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.7rem;
    letter-spacing: 0;
    color: #373f49;
    text-align: center;
    flex: 1.5;
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: 0;
    color: #788293;
    text-align: center;
    flex: 2;
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: 500px) {
    width: 80%;
    padding: 0rem 2rem;
    max-width: 500px;
    justify-content: center;
    position: relative;
    opacity: 100%;
    animation: none;
    margin: auto;
    max-height: 450px;
    min-height: 450px;
    h3 {
      margin-bottom: 0;
    }

    p {
      line-height: 2rem;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    max-width: 30rem;
    padding: 2rem;
    justify-self: center;
    width: 100%;
    position: relative;
    opacity: 100%;
    animation: none;
    margin: auto;
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 991px) {
    padding: 3.5rem;
    max-width: 30rem;
    width: 150%;
    margin-left: -20px;
    &:last-child {
      justify-self: center;
    }
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1440px) {
    width: 160%;
    margin-left: -20px;
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1750px) {
    width: 180%;
    margin-left: -70px;
  }

  @media (min-width: 1751px) {
    margin-left: -30px;
  }
`

export const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 150px;
  padding: 10px;
  @media only screen and (max-width: 500px) {
    max-width: 180px;
  }

  @media only screen and (min-width: 501px) and (max-width: 768px) {
    max-width: 180px;
  }

  @media only screen and (min-width: 769px) and (max-width: 991px) {
    max-width: 180px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1440px) {
    max-width: 150px;
  }

  @media only screen and (min-width: 1441px) {
    max-width: 180px;
  }
`
