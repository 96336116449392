//Lybraries
import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import ImageGallery from 'react-image-gallery'
import WillPicture from '../../../assets/image/edge_members_photos/will_edge.png'
import MarkMaggio from '../../../assets/image/edge_members_photos/mark_maggio.jpeg'
import KevinFrawley from '../../../assets/image/edge_members_photos/kevin_frawley.jpg'

//components
import CustomButton from 'components/CustomButton'
import SliderDes from './sliderDes'

// styles
import 'react-image-gallery/styles/css/image-gallery.css'
import {
  GalleryContainer,
  TeamWorkingSection,
  TextContainer
} from './teamWorking.style'
const TeamWorking = () => {
  const { strapiHome } = useStaticQuery(graphql`
    {
      strapiHome {
        backgroundTeamSection {
          publicURL
        }
        testimonialsImage {
          caption
          localFile {
            publicURL
          }
        }
        dataHome {
          teamWorkingSection {
            title
            subTitle
            linkButton
            testimonailsData {
              image
              content
              name
              position
            }
          }
        }
      }
    }
  `)

  const tempStaticMembersPictures = [
    {
      caption: 'CEO',
      localFile: {
        publicURL: WillPicture
      }
    },
    {
      caption: 'CSO',
      localFile: {
        publicURL: MarkMaggio
      }
    },
    {
      caption: 'SVP Customer Success',
      localFile: {
        publicURL: KevinFrawley
      }
    },
  ]

  const {
    // testimonialsImage,
    dataHome: {
      teamWorkingSection: { title, subTitle, linkButton, testimonailsData }
    },
    backgroundTeamSection
  } = strapiHome

  const images = []

  testimonailsData.forEach((element, index) => {
    const image = tempStaticMembersPictures.find(
      (item) => item.caption === element.image
    )
    testimonailsData[index].url = image.localFile.publicURL
    images.push({
      thumbnail: `${element.url}`,
      thumbnailAlt: `${element.image}`,
      description: <SliderDes data={element} title={title} />
    })
  })

  const handleClickJobs = () => {
    navigate(linkButton)
  }

  return (
    <TeamWorkingSection url={backgroundTeamSection.publicURL}>
      <section className="content">
        <TextContainer>
          <h1 className="title">{title}</h1>
          <p className="description">{subTitle}</p>
          <CustomButton
            className="hide button"
            onClick={handleClickJobs}
            text="Join our team"
          />
        </TextContainer>
        <GalleryContainer size={220} items={images.length}>
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </GalleryContainer>
      </section>
    </TeamWorkingSection>
  )
}

export default TeamWorking
