//  libraries
import React, { useState } from 'react'
import ReactGA from 'react-ga'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
//  components
import StyledCarousel from 'components/Carrousel'
import CustomButton from 'components/CustomButton'
//  styles
import {
  Container,
  Content,
  Card,
  UpperCard,
  InnerCard,
  Image
} from './section2HomeNew.style.js'

const CustomCard = ({ title, content, url }) => {
  const isTablet = useMediaQuery({ query: '(max-width: 769px)' })
  const [displayUpper, setDisplayUpper] = useState(false)

  

  return (
    <Card
      onMouseEnter={() => setDisplayUpper(true)}
      onMouseLeave={() => setDisplayUpper(false)}
    >
      <InnerCard>
        <div
          style={{
            minHeight: 180,
            maxHeight: 180,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', visibility: (isTablet || displayUpper) ? 'hidden':'visible',
            transition: '0.3s'
          }}
        >
          <Image src={url} alt="icon" />
        </div>
        <h3> {title} </h3>
      </InnerCard>
      {(isTablet || displayUpper) && (
        <UpperCard>
          <div
            style={{
              minHeight: 180,
              maxHeight: 180,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Image src={url} alt="icon" />
          </div>
          <h3> {title} </h3>
          <p> {content} </p>
        </UpperCard>
      )}
    </Card>
  )
}

CustomCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string
}

const Section2Home = () => {
  const data = useStaticQuery(graphql`
    {
      strapiHome {
        dataHome {
          info: newOurServices {
            title
            data {
              title
              content
              image
              linkTo
            }
          }
        }
        images: newOurServicesPng {
          caption
          url
        }
      }
    }
  `)

  const {
    dataHome: { info },
    images
  } = data.strapiHome

  info.data.forEach((element, index) => {
    const image = images.find((item) => item.caption === element.image)
    info.data[index].url = image.url
  })

  const [index, setIndex] = useState(0)
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const handleClick = (event) => {
    navigate('edge-solutions')
    ReactGA.event({
      category: 'Outbound Link',
      action: 'click',
      label: event.target.href
    })
  }

  return (
    <Container>
      <h2>{info.title}</h2>
      <Content>
        <div className="cards">
          {info.data.map((item, index) => {
            return <CustomCard key={index} {...item} />
          })}
        </div>
        <div className="cardsMobile">
          <StyledCarousel
            home={index.toString()}
            onSelect={handleSelect}
            activeIndex={index}
            indicators={false}
            nextIcon={<span className="slider-next-icon" />}
            prevIcon={<span className="slider-prev-icon" />}
            prevLabel=""
            nextLabel=""
          >
            {info.data.map((item, index) => {
              return (
                <StyledCarousel.Item key={index}>
                  <CustomCard key={index} {...item} />
                </StyledCarousel.Item>
              )
            })}
          </StyledCarousel>
        </div>
      </Content>
      <CustomButton
        className="learn-button"
        text="Learn More"
        onClick={handleClick}
      />
    </Container>
  )
}

export default Section2Home
